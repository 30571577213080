// Landing Page Main ====================================
.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 600px) {
	.content-container {
		margin-left: 58px !important;
	}
}

.section-container {
  box-shadow: none !important;
  width: 100% !important;
  margin-top: 0 !important;
  padding: 0 !important;
  justify-content: center !important;
	position: relative !important;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

// About Section ========================================
#about {
	min-height: 100vh !important;
}

// PaulBot Section ======================================
#paulbot {
	min-height: 100vh !important;
}

// Resume Section =======================================
#resume {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
	min-height: 100vh !important;
  .MuiTypography-root {
    .MuiAccordion-heading {
      padding: 0;
    }
  }
}

.timeline-text-container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.timeline-text-container:hover {
  transform: scale(1.01);
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .hide-on-small {
    display: none;
  }
}

// Portfolio Section ====================================
#portfolio {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: none;
	// min-height: 100vh !important;
  .portfolio-carousel {
    .MuiPaper-elevation {
      box-shadow: none;
    }
  }
}

// Contact Section ======================================
#contact {
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
	height: 80vh;
	// max-height: 800px !important;
}
