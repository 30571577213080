.amandas-page-container {
    text-align: center;
    min-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    color: white;
}

.countdown-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.greeting-text, .love-note-text {
    font-size: 16px;
    text-decoration: none;
}

.your-birthday-countdown, .my-birthday-countdown, .wedding-countdown {
    margin-top: 6px;
    margin-right: 10px;
    display: inline-block;
}

.your-birthday-countdown:hover, .my-birthday-countdown:hover, .wedding-countdown:hover {
    text-decoration: underline;
    color: #007BFF;
    cursor: pointer;
}
