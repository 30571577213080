@tailwind base;
@tailwind components;
@tailwind utilities;

 :root {
    --primary-light: #292929;
    --primary-main: #292929;
    --primary-dark: #1c1c1c;
    --primary-contrastText: #F0F0F0;

    --secondary-light: #424242;
    --secondary-main: #424242;
    --secondary-dark: #2c2c2c;
    --secondary-contrastText: #F0F0F0;

    --primary-accent-light: #51859B;
    --primary-accent-main: #51859B;
    --primary-accent-dark: #3a5a6b;
    --primary-accent-contrastText: #F0F0F0;

    --secondary-accent-light: #7DC54D;
    --secondary-accent-main: #7DC54D;
    --secondary-accent-dark: #5a8a3a;
    --secondary-accent-contrastText: #292929;
}

 html, body {
    background-color: var(--primary-main);
    min-height: 100vh;
}

body {
    font-family: 'Roboto Mono', monospace;
    padding: 0;
    margin: 0 auto;
    color: var(--primary-contrastTex);
}
